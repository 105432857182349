import useMethods from "../../useMethods";
import { User } from "../Users/UsersApiTypes";
import { Organisation, OrganisationInvite } from "./OrganisationsApiTypes";

const useOrganisationsApi = () => {
  const methods = useMethods();

  const get = async (organisationId: string): Promise<Organisation> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}`
    );
    return result.data;
  };

  const list = async (
    userId: string,
    search?: string
  ): Promise<Organisation[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/organisations${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listOrganisationMembers = async (
    organisationId: string,
    search?: string
  ): Promise<User[]> => {
    const result = await methods.get(
      `${
        process.env.REACT_APP_API_URL
      }/organisations/${organisationId}/members${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listTeamMembers = async (
    organisationId: string,
    teamId: string,
    search?: string
  ): Promise<User[]> => {
    const result = await methods.get(
      `${
        process.env.REACT_APP_API_URL
      }/organisations/${organisationId}/teams/${teamId}/members${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const create = async (organisation: Organisation): Promise<Organisation> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/organisations`,
      organisation
    );
    return result.data;
  };

  const inviteUser = async (options: {
    organisationId: string;
    email: string;
  }): Promise<OrganisationInvite> => {
    const { organisationId, email } = options;

    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/invites`,
      {
        email,
      }
    );
    return result.data;
  };

  const resendInvite = async (options: {
    inviteId: string;
    organisationId: string;
  }): Promise<OrganisationInvite> => {
    const { organisationId, inviteId } = options;

    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/invites/${inviteId}/resend`,
      {}
    );
    return result.data;
  };

  const deleteInvite = async (options: {
    inviteId: string;
    organisationId: string;
  }): Promise<OrganisationInvite> => {
    const { organisationId, inviteId } = options;

    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/invites/${inviteId}`
    );
    return result.data;
  };

  const listInvites = async (
    organisationId: string
  ): Promise<OrganisationInvite[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/invites`
    );
    return result.data;
  };

  const update = async (organisation: Organisation): Promise<Organisation> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/organisations/${organisation.id}`,
      organisation
    );
    return result.data;
  };

  const deleteOne = async (organisationId: string): Promise<Organisation> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}`
    );
    return result.data;
  };

  return {
    list,
    create,
    update,
    deleteOne,
    get,
    inviteUser,
    listInvites,
    resendInvite,
    deleteInvite,
    listTeamMembers,
    listOrganisationMembers,
  };
};

export default useOrganisationsApi;
