import { Flex, Text } from "@chakra-ui/react";
import styles from "./Card.module.scss";

export const CardHeader = ({
  title,
  subtitle,
  action,
}: {
  title: string;
  subtitle?: string;
  action?: JSX.Element | JSX.Element[];
}) => {
  return (
    <Flex className={styles.cardHeader}>
      <Flex flexDirection={"column"}>
        <Text className={styles.header}>{title}</Text>
        {subtitle && <Text className={styles.subtitle}>{subtitle}</Text>}
      </Flex>
      {action}
    </Flex>
  );
};

export const CardDataLabel = ({ children }: { children: any }) => {
  return <Flex className={styles.dataLabel}>{children}</Flex>;
};

export const CardDataSubLabel = ({
  children,
  marginLeft,
  color,
}: {
  children: any;
  marginLeft?: number | string;
  color?: string;
}) => {
  return (
    <Text color={color} marginLeft={marginLeft} className={styles.dataSubLabel}>
      {children}
    </Text>
  );
};

export const Card = ({
  children,
  isPadded = true,
  width,
  height,
  marginTop,
  marginBottom,
}: {
  children: any;
  isPadded?: boolean;
  width?: number | string | number[] | string[];
  height?: number | string;
  marginTop?: number | string;
  marginBottom?: number | string;
}) => {
  return (
    <Flex
      marginTop={marginTop}
      marginBottom={marginBottom}
      flexDirection={"column"}
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      className={`${styles.card} ${isPadded ? styles.padding : ""}`}
    >
      {children}
    </Flex>
  );
};
