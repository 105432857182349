import { Badge, Button, useDisclosure } from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { Placeholder, Plus, TrashSimple } from "phosphor-react";
import CustomTable, { ColumnType } from "../../../../UIKit/Table/Table";
import { ButtonTableRow } from "../../../../UIKit/Table/ButtonTableRow";
import useTeamsMutation from "../../../../Api/Resources/Teams/TeamsMutation";
import { AvatarTableRow } from "../../../../UIKit/Table/AvatarTableRow";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useMemo } from "react";
import { Team } from "../../../../Api/Resources/Teams/TeamsApiTypes";
import { AddTeamMemberModal } from "./AddTeamMemberModal";
import { UserTableRow } from "../../../../UIKit/Table/UserTableRow";
import useTeamsApi from "../../../../Api/Resources/Teams/TeamsApi";
import { useQuery } from "react-query";

export const TeamMembersTab = ({ team }: { team?: Team }) => {
  const teamsApi = useTeamsApi();
  const addMemberModal = useDisclosure();
  const { removeTeamMember } = useTeamsMutation();

  const teamId = team?.id;

  const organisationId = useMemo(() => {
    return team?.organisation?.id;
  }, [team]);

  const { data: teamMembers, isFetching: isFetchingTeamMembers } = useQuery(
    ["teamMembers", teamId],
    () => {
      if (teamId && organisationId) {
        return teamsApi.listTeamMembers(teamId, organisationId);
      }
    }
  );

  const onRemoveTeamMember = (userId: string) => {
    removeTeamMember.mutate({
      teamId: team?.id!,
      organisationId: team?.organisation?.id,
      userId,
    });
  };

  const rows = useMemo(() => {
    return (
      teamMembers?.map((member) => {
        const role = member.roles.length ? member.roles[0] : undefined;

        return {
          role: (
            <Badge px={2} py={1}>
              {role?.role}
            </Badge>
          ),
          user: member,
          lastWorkedOn: "Some project",
        };
      }) ?? []
    );
  }, [teamMembers]);

  return (
    <>
      <AddTeamMemberModal
        organisationId={team?.organisation?.id}
        teamId={team?.id}
        isOpen={addMemberModal.isOpen}
        onClose={addMemberModal.onClose}
      />

      <Card isPadded={false}>
        <CardHeader
          title="Members"
          subtitle="View, edit and remove team members."
          action={
            <Button
              leftIcon={<Plus weight="bold" />}
              variant={"outline"}
              colorScheme={"blue"}
              onClick={addMemberModal.onOpen}
            >
              Add Member
            </Button>
          }
        />

        <CustomTable
          isLoading={isFetchingTeamMembers}
          placeholder={{
            label: "No team members",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            const { column } = options;

            switch (column.type) {
              case ColumnType.BUTTON: {
                return (
                  <ButtonTableRow
                    icon={TrashSimple}
                    onItemClicked={({ rowIndex, columnIndex }) => {
                      if (!team?.members) return;

                      const member = team?.members[rowIndex];

                      if (member?.id) {
                        onRemoveTeamMember(member.id);
                      }
                    }}
                    {...options}
                  />
                );
              }
              case ColumnType.AVATAR: {
                return <AvatarTableRow {...options} />;
              }
              case ColumnType.USER: {
                return <UserTableRow {...options} />;
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={rows}
          columns={[
            { label: "Name", accessor: "user", type: ColumnType.USER },
            { label: "Last Worked On", accessor: "lastWorkedOn" },
            { label: "Team Role", accessor: "role" },
            {
              label: "Remove",
              accessor: "remove",
              isNumeric: true,
              type: ColumnType.BUTTON,
            },
          ]}
        />
      </Card>
    </>
  );
};
