import useMethods from "../../useMethods";
import { Project } from "../Projects/ProjectsApiTypes";
import { User } from "../Users/UsersApiTypes";
import { AddTeamMemberReqest, CreateTeamRequest, Team } from "./TeamsApiTypes";

const useTeamsApi = () => {
  const methods = useMethods();

  const get = async (teamId: string): Promise<Team> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/teams/${teamId}`
    );
    return result.data;
  };

  const listProjects = async (
    teamId: string,
    search: string = ""
  ): Promise<Project[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/teams/${teamId}/projects`
    );
    return result.data;
  };

  const listTeamMembers = async (
    teamId: string,
    organisationId: string,
    search: string = ""
  ): Promise<User[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/members`
    );
    return result.data;
  };

  const listForOrganisation = async (
    organisationId: string,
    search?: string
  ): Promise<Team[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listForUser = async (userId: string): Promise<Team[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/teams`
    );
    return result.data;
  };

  const create = async (
    createTeamRequest: CreateTeamRequest
  ): Promise<Team> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/teams`,
      createTeamRequest
    );
    return result.data;
  };

  const addTeamMember = async (
    addTeamMemberRequest: AddTeamMemberReqest
  ): Promise<Team> => {
    const { teamId, userId } = addTeamMemberRequest;
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/teams/${teamId}/members`,
      {
        userId,
      }
    );
    return result.data;
  };

  const removeTeamMember = async (
    addTeamMemberRequest: AddTeamMemberReqest
  ): Promise<Team> => {
    const { teamId, userId } = addTeamMemberRequest;
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/teams/${teamId}/members/${userId}`
    );
    return result.data;
  };

  const update = async (team: Team): Promise<Team> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/teams/${team.id}`,
      team
    );
    return result.data;
  };

  const deleteOne = async (teamId: string): Promise<Team> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/teams/${teamId}`
    );
    return result.data;
  };

  return {
    listForOrganisation,
    listForUser,
    create,
    update,
    deleteOne,
    get,
    addTeamMember,
    removeTeamMember,
    listProjects,
    listTeamMembers,
  };
};

export default useTeamsApi;
