import useMethods from "../../useMethods";
import { User } from "../Users/UsersApiTypes";
import {
  CreateProjectRequest,
  Project,
  UpdateProjectRequest,
} from "./ProjectsApiTypes";

const useProjectsApi = () => {
  const methods = useMethods();

  const listForUser = async (options: {
    userId: string;
    search?: string;
  }): Promise<Project[]> => {
    const { userId, search = "" } = options;

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/projects${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listUsers = async (
    projectId: string,
    search: string = ""
  ): Promise<User[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}/members${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const get = async (projectId: string): Promise<Project> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}`
    );
    return result.data;
  };

  const create = async (project: CreateProjectRequest): Promise<Project> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/projects`,
      project
    );
    return result.data;
  };

  const update = async (project: UpdateProjectRequest): Promise<Project> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/projects/${project.id}`,
      project
    );
    return result.data;
  };

  const deleteOne = async (projectId: string): Promise<Project> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}`
    );
    return result.data;
  };

  return {
    listForUser,
    create,
    update,
    get,
    deleteOne,
    listUsers,
  };
};

export default useProjectsApi;
