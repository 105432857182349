import {
  Avatar,
  Button,
  useDisclosure,
  Badge,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import {
  Buildings,
  EnvelopeSimple,
  EnvelopeSimpleOpen,
  PencilSimple,
  TrashSimple,
  UserCircle,
} from "phosphor-react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useOrganisationsApi from "../../../../../Api/Resources/Organisations/OrganisationsApi";
import useOrganisationMutation from "../../../../../Api/Resources/Organisations/OrganisationsMutation";
import useTeamsApi from "../../../../../Api/Resources/Teams/TeamsApi";
import TitledPage from "../../../../../Components/TitledPage";
import { Card, CardHeader } from "../../../../../UIKit/Card/Card";
import { InviteUserModal } from "../Components/InviteUserModal";
import { ModifyOrganisationModal } from "../Components/ModifyOrganisationModal";
import { ModifyTeamModal } from "../Components/ModifyTeamModal";
import { formatDistanceToNow } from "date-fns";
import CustomTable from "../../../../../UIKit/Table/Table";
import { StandardTableRow } from "../../../../../UIKit/Table/StandardTableRow";

const ViewOrganisationPage = () => {
  const { organisationId } = useParams();
  const organisationModal = useDisclosure();
  const inviteUserModal = useDisclosure();
  const teamModal = useDisclosure();

  const organisationsApi = useOrganisationsApi();
  const teamsApi = useTeamsApi();
  const { resendInvite, deleteInvite } = useOrganisationMutation();

  const { data: organisation, isFetching: isFetchingOrganisation } = useQuery(
    ["organisation", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.get(organisationId);
      }

      return;
    }
  );

  const { data: members, isFetching: isFetchingMembers } = useQuery(
    ["organisationMembers", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.listOrganisationMembers(organisationId);
      }

      return;
    }
  );

  const { data: invites, isFetching: isFetchingInvites } = useQuery(
    ["organisationInvitations", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.listInvites(organisationId);
      }

      return;
    }
  );

  const { data: teams, isFetching: isFetchingTeams } = useQuery(
    ["teams", organisationId],
    () => {
      if (organisationId) {
        return teamsApi.listForOrganisation(organisationId);
      }

      return;
    }
  );

  const onResend = (inviteId: string) => {
    resendInvite.mutate({
      organisationId: organisationId!,
      inviteId,
    });
  };

  const onDeleteInvite = (inviteId: string) => {
    deleteInvite.mutate({
      organisationId: organisationId!,
      inviteId,
    });
  };

  return (
    <TitledPage
      isLoading={isFetchingOrganisation}
      title={organisation?.name ?? ""}
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
        {
          label: "Organisations",
          link: "/account/organisations",
        },
      ]}
      action={{
        label: "Edit Organisation",
        icon: PencilSimple,
        trigger: organisationModal.onOpen,
      }}
      placeholderIcon={Buildings}
      placeholderMessage={"No organisations."}
      showPlaceholder={!organisation}
    >
      <ModifyOrganisationModal
        organisation={organisation}
        isOpen={organisationModal.isOpen}
        onClose={organisationModal.onClose}
      />

      <InviteUserModal
        organisationId={organisation?.id}
        isOpen={inviteUserModal.isOpen}
        onClose={inviteUserModal.onClose}
      />

      <ModifyTeamModal
        organisationId={organisation?.id!}
        isOpen={teamModal.isOpen}
        onClose={teamModal.onClose}
      />

      <VStack marginTop={"16px"} spacing={4} flexDirection={"column"}>
        <Card>
          <CardHeader
            title="Plan Details"
            subtitle={"The plan information for this organisation."}
          />
          Daybreak Standard
        </Card>
        <Card>
          <CardHeader
            title="Members"
            subtitle={"View and edit organisation members."}
          />

          <CustomTable
            placeholder={{
              label: "No users",
              icon: UserCircle,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={
              members?.map((user) => {
                let role = user.roles.length ? user.roles[0] : undefined;

                return {
                  icon: (
                    <Avatar
                      name={`${user.givenName} ${user.familyName}`}
                      src={user.photoUrl}
                    />
                  ),
                  name: user.displayName,
                  lastSeen:
                    user?.lastSeen &&
                    formatDistanceToNow(new Date(user?.lastSeen), {
                      addSuffix: true,
                    }),
                  role: (
                    <Badge px={2} py={1}>
                      {role?.role}
                    </Badge>
                  ),
                  remove: (
                    <IconButton
                      isDisabled={role?.role === "admin"}
                      aria-label="Remove User"
                      icon={<TrashSimple weight="bold" />}
                    />
                  ),
                };
              }) ?? []
            }
            columns={[
              { label: "", accessor: "icon" },
              { label: "Name", accessor: "name" },
              { label: "Last Seen", accessor: "lastSeen" },
              { label: "Role", accessor: "role" },
              { label: "Remove", accessor: "remove", isNumeric: true },
            ]}
          />
        </Card>
        <Card>
          <CardHeader
            title="Invitations"
            subtitle={"View and send new invitations."}
            action={
              <Button
                variant={"outline"}
                leftIcon={<EnvelopeSimpleOpen weight="bold" />}
                colorScheme="blue"
                onClick={inviteUserModal.onOpen}
              >
                Send Invite
              </Button>
            }
          />

          <CustomTable
            placeholder={{
              label: "No active invitations",
              icon: EnvelopeSimple,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={
              invites?.map((invite) => {
                return {
                  email: invite.email,
                  ignored: invite.ignored ? "Yes" : "No",
                  resend: (
                    <Button
                      onClick={() => onResend(invite.id)}
                      isDisabled={!invite.ignored}
                    >
                      Resend
                    </Button>
                  ),
                  delete: (
                    <Button onClick={() => onDeleteInvite(invite.id)}>
                      Delete
                    </Button>
                  ),
                };
              }) ?? []
            }
            columns={[
              { label: "Email", accessor: "email" },
              { label: "Ignored", accessor: "ignored" },
              { label: "Resend", accessor: "resend" },
              { label: "Delete", accessor: "delete" },
            ]}
          />
        </Card>
      </VStack>
    </TitledPage>
  );
};

export default ViewOrganisationPage;
