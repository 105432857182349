import { useMemo, useState } from "react";
import {
  Switch,
  Spinner,
  IconButton,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import useProjectMutation from "../../../../Api/Resources/Projects/ProjectsMutation";
import { useParams } from "react-router-dom";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import { useQuery } from "react-query";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import useEntriesApi from "../../../../Api/Resources/Entries/EntriesApi";
import useEntryMutations from "../../../../Api/Resources/Entries/EntriesMutations";
import { Entry } from "../../../../Api/Resources/Entries/EntriesApiTypes";
import CustomTable from "../../../../UIKit/Table/Table";
import { format, parseISO } from "date-fns";
import { formatTime } from "../../../../Utilities/TimeUtility";
import { CheckCircle, Placeholder } from "phosphor-react";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";

const ApprovalsTab = () => {
  const [requireApproval, setRequireApproval] = useState<boolean>();
  const { updateProject } = useProjectMutation();
  const { approveEntry } = useEntryMutations();
  const { projectId } = useParams();
  const projectsApi = useProjectsApi();
  const entriesApi = useEntriesApi();

  const { data: project, isFetching: isFetchingProject } = useQuery(
    ["project", projectId],
    () => {
      if (projectId) {
        return projectsApi.get(projectId);
      }

      return;
    }
  );

  const organisationId = useMemo(() => {
    return project?.organisation?.id;
  }, [project]);

  const {
    data: awaitingApprovalEntries,
    isFetching: isFetchingAwaitingApprovalEntries,
  } = useQuery(["awaitingApprovalEntries", projectId, organisationId], () => {
    if (organisationId && projectId) {
      return entriesApi.listForProject({
        organisationId,
        projectId,
        approved: false,
        categories: [],
        members: [],
      });
    }

    return;
  });

  const onRequireApprovals = (value: any) => {
    setRequireApproval(value);
    updateProject.mutate({
      id: project!.id,
      requireApproval: value,
    });
  };

  const onApproveEntry = (entry: Entry) => {
    approveEntry.mutate({
      approved: true,
      entry,
      projectId: project?.id!,
      organisationId: organisationId!,
    });
  };

  return (
    <>
      Enable approval for entries within this project.{" "}
      <Switch
        isChecked={requireApproval ?? false}
        onChange={(e) => onRequireApprovals(e.target.checked)}
        size="lg"
      />
      {requireApproval && (
        <Card>
          {isFetchingAwaitingApprovalEntries ? (
            <Spinner />
          ) : (
            <>
              <CardHeader
                title="Awaiting Approval"
                subtitle="View entries awaiting approval for this project."
              />
              <CustomTable
                placeholder={{
                  label: "No approvals",
                  icon: Placeholder,
                }}
                renderRow={(options) => {
                  return <StandardTableRow {...options} />;
                }}
                rows={
                  awaitingApprovalEntries?.data?.map((entry) => {
                    return {
                      description: entry.description,
                      category: entry.category?.name,
                      date: format(parseISO(entry.date), "yyyy-MM-dd"),
                      time: formatTime(entry.time),
                      users: (
                        <AvatarGroup size="sm" max={2}>
                          {entry?.users?.map((user) => {
                            return <Avatar name={user.displayName} />;
                          })}
                        </AvatarGroup>
                      ),
                      approve: (
                        <IconButton
                          onClick={() => onApproveEntry(entry)}
                          aria-label="Approve button"
                          icon={<CheckCircle size={24} />}
                        />
                      ),
                    };
                  }) ?? []
                }
                columns={[
                  { label: "Description", accessor: "description" },
                  { label: "Category", accessor: "category" },
                  { label: "Date", accessor: "date" },
                  { label: "Time", accessor: "time" },
                  { label: "Users", accessor: "users" },
                  {
                    label: "Approve",
                    accessor: "approve",
                    isNumeric: true,
                  },
                ]}
              />
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default ApprovalsTab;
