import useMethods from "../../useMethods";
import { AnalysisSummary, GraphData } from "./AnalysisApiTypes";

const useAnalysisApi = () => {
  const methods = useMethods();

  const getQueryString = (options: {
    startDate?: string;
    endDate?: string;
    projects: string[];
    members: string[];
    categories: string[];
  }) => {
    const { startDate, endDate } = options;
    const dates =
      startDate && endDate
        ? `startDate=${startDate}&endDate=${endDate}`
        : undefined;
    const categories =
      options.categories.length > 0
        ? `categories=${options.categories.join(",")}`
        : undefined;
    const members =
      options.members.length > 0
        ? `members=${options.members.join(",")}`
        : undefined;
    const projects =
      options.projects.length > 0
        ? `projects=${options.projects.join(",")}`
        : undefined;

    let queryString = "";
    if (dates || categories || members || projects) {
      queryString = `?${[dates, categories, members, projects]
        .filter((x) => x)
        .join("&")}`;
    }

    return queryString;
  };

  const exportData = async (options: {
    startDate?: string;
    endDate?: string;
    projects: string[];
    members: string[];
    categories: string[];
  }): Promise<any> => {
    const queryString = getQueryString(options);

    const result = await methods.save(
      `${process.env.REACT_APP_API_URL}/analysis/entries/export${queryString}`
    );

    return result;
  };

  const summary = async (options: {
    startDate: string;
  }): Promise<AnalysisSummary> => {
    const { startDate } = options;
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/analysis/summary?startDate=${startDate}`
    );

    return result.data;
  };

  const graph = async (options: {
    startDate?: string;
    endDate?: string;
    projects: string[];
    members: string[];
    categories: string[];
  }): Promise<GraphData> => {
    const queryString = getQueryString(options);

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/analysis/graph${queryString}`
    );

    return result.data;
  };

  return {
    exportData,
    summary,
    graph
  };
};

export default useAnalysisApi;
