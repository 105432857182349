import useMethods from "../../useMethods";
import { OrganisationInvite } from "../Organisations/OrganisationsApiTypes";
import { User } from "./UsersApiTypes";

const useUsersApi = () => {
  const methods = useMethods();

  const get = async (userId: string): Promise<User> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}`
    );
    return result.data;
  };

  const list = async (userId: string): Promise<OrganisationInvite[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users`
    );
    return result.data;
  };

  const listInvites = async (userId: string): Promise<OrganisationInvite[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/invites`
    );
    return result.data;
  };

  const acceptInvite = async (options: {
    inviteId: string;
    userId: string;
  }): Promise<OrganisationInvite> => {
    const { userId, inviteId } = options;
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/users/${userId}/invites/${inviteId}/accept`,
      {}
    );
    return result.data;
  };

  const ignoreInvite = async (options: {
    inviteId: string;
    userId: string;
  }): Promise<OrganisationInvite> => {
    const { userId, inviteId } = options;
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/users/${userId}/invites/${inviteId}/ignore`,
      {}
    );
    return result.data;
  };

  const signIn = async (
    email: string,
    password: string
  ): Promise<{ redirectUrl: string }> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      { email, password }
    );
    return result.data;
  };

  const signUp = async (options: {
    givenName: string;
    familyName: string;
    email: string;
    password: string;
  }): Promise<{ redirectUrl: string }> => {
    const { password, email, givenName, familyName } = options;

    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/auth/sign-up`,
      { email, password, givenName, familyName }
    );
    return result.data;
  };

  return {
    get,
    signIn,
    signUp,
    listInvites,
    acceptInvite,
    ignoreInvite,
  };
};

export default useUsersApi;
