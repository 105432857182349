import { Button, useDisclosure } from "@chakra-ui/react";
import { Kanban, Plus } from "phosphor-react";
import { useMemo, useState } from "react";
import TitledPage from "../../../Components/TitledPage";
import { useQuery } from "react-query";
import ListContainer, { Group, ListGroup } from "../../../UIKit/List/List";
import { ProjectsListItem } from "./Components/ProjectsListItem/ProjectsListItem";
import { Project } from "../../../Api/Resources/Projects/ProjectsApiTypes";
import { useNavigate } from "react-router-dom";
import { ModifyProjectModal } from "./Components/ModifyProjectModal";
import { Item, ItemType } from "../../../UIKit/ListItem/ListItem";
import useProjectsApi from "../../../Api/Resources/Projects/ProjectsApi";
import { groupBy } from "../../../Utilities/GroupBy";
import { Team } from "../../../Api/Resources/Teams/TeamsApiTypes";
import { useUserId } from "../../../Store/AuthStore";

const ListProjectsPage = () => {
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<Project>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const projectsApi = useProjectsApi();
  const userId = useUserId();

  const { data: _projects, isFetching: isFetchingProjects } = useQuery(
    ["projects", userId],
    () => {
      return projectsApi.listForUser({ userId: userId! });
    }
  );

  const onItemSelected = (id: string) => {
    navigate(`/projects/${id}`);
  };

  const onPrepareModalClose = () => {
    setSelectedProject(undefined);
    onClose();
  };

  const hasProjects = useMemo(() => {
    return (_projects ?? []).length > 0;
  }, [_projects]);

  const groups = useMemo(() => {
    const DEFAULT_TEAM_LABEL = "My Projects";
    const DEFAULT_TEAM_ID = "my_projects";

    const result: Group[] = [];
    const groupedProjects = groupBy(
      _projects ?? [],
      (item) => item.team?.id,
      DEFAULT_TEAM_ID
    );

    Object.keys(groupedProjects).map((groupKey) => {
      const projects = groupedProjects[groupKey];

      let team: Team | undefined;
      if (projects.length > 0) {
        team = projects[0].team;
      }

      const items: Item[] = groupedProjects[groupKey].map((project) => {
        return {
          id: project.id!,
          type: ItemType.Item,
          label: project.name,
          description: project.description,
          color: project.color,
          data: project,
        };
      });

      result.push({
        title: team?.name ?? DEFAULT_TEAM_LABEL,
        id: team?.id ?? DEFAULT_TEAM_ID,
        items,
      });
    });

    return result;
  }, [_projects]);

  return (
    <TitledPage
      isResponsive={false}
      title={"Projects"}
      action={{
        label: "New Project",
        icon: Plus,
        trigger: onOpen,
      }}
      placeholderIcon={Kanban}
      placeholderMessage={"No projects"}
      isLoading={isFetchingProjects}
      showPlaceholder={!hasProjects}
    >
      <ModifyProjectModal
        project={selectedProject}
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      />

      <ListContainer>
        <ListGroup
          onClick={(id) => onItemSelected(id)}
          groups={groups}
          listItem={ProjectsListItem}
        />
      </ListContainer>
    </TitledPage>
  );
};

export default ListProjectsPage;
