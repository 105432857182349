import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { modalTheme } from "./CustomThemes/Modal";
import { tableTheme } from "./CustomThemes/Table";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  styles: {
    global: {
      "html, body": {
        backgroundColor: "background200",
      },
    },
  },
  fonts: {
    heading: `'Sora', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ["32px"],
      fontWeight: "bold",
      lineHeight: "110%",
      letterSpacing: "-2%",
      fontFamily: '"Sora", sans-serif',
    },
    h2: {
      fontSize: "24px",
    },
    h3: {
      fontSize: "16px",
      fontWeight: 300,
    },
  },
  semanticTokens: {
    colors: {
      error: "red.500",
      text: {
        default: "gray.900",
        _dark: "gray.50",
      },
      border100: {
        default: "#E4E4E8",
        _dark: "#22232F",
      },
      border200: {
        default: "#C9CAD1",
        _dark: "#22232F",
      },
      border300: {
        default: "#E4E4E8",
        _dark: "#16171F",
      },
      border400: {
        default: "#E4E4E8",
        _dark: "#101115",
      },
      border500: {
        default: "#E4E4E8",
        _dark: "#101115",
      },
      background100: {
        default: "#FFFFFF",
        _dark: "#080914",
      },
      background200: {
        default: "#FBFBFB",
        _dark: "#111217",
      },
      background300: {
        default: "#F3F3F4",
        _dark: "#161720",
      },
      background400: {
        default: "#E4E4E8",
        _dark: "#3F414B",
      },
      background500: {
        default: "#444754",
        _dark: "#6E707B",
      },
      blue100: {
        default: "#CDE8FF",
        _dark: "#CDE8FF",
      },
      blue200: {
        default: "#AFD9FF",
        _dark: "#AFD9FF",
      },
      blue300: {
        default: "#84C2FA",
        _dark: "#84C2FA",
      },
      blue400: {
        default: "#5EA3E3",
        _dark: "#5EA3E3",
      },
      blue500: {
        default: "#3088D9",
        _dark: "#3088D9",
      },
      yellow100: {
        default: "#FFF3DE",
        _dark: "#F36813",
      },
      yellow200: {
        default: "#FFE9C5",
        _dark: "#966635",
      },
      yellow300: {
        default: "#FFD898",
        _dark: "#FFAA1D",
      },
      yellow400: {
        default: "#FFAA1D",
        _dark: "#FFD898",
      },
      yellow500: {
        default: "#F38413",
        _dark: "#FFE9C5",
      },
      yellow600: {
        default: "#F36813",
        _dark: "#FFF3DE",
      },
    },
  },
  components: { Modal: modalTheme, Table: tableTheme },
});

export const AsyncSelectCustomStyle = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    control: {
      color: "orange",
    },
    borderColor: "var(--chakra-colors-chakra-border-color)",
    backgroundColor: "var(--chakra-colors-background100)",
  }),
};

export default theme;
