import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  HStack,
} from "@chakra-ui/react";
import { Eye, PencilSimple } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import useProjectsApi from "../../../Api/Resources/Projects/ProjectsApi";
import TitledPage from "../../../Components/TitledPage";
import { ModifyProjectModal } from "../List/Components/ModifyProjectModal";
import EntriesTab from "./Components/EntriesTab";
import CategoriesTab from "./Components/CategoriesTab";
import BillingTab from "./Components/BillingTab";
import ApprovalsTab from "./Components/ApprovalsTab";

const ViewProjectPage = () => {
  const [isProjectModalOpen, setIsProjectModalOpen] = useState<boolean>(false);
  const [requireApproval, setRequireApproval] = useState<boolean>();
  const projectsApi = useProjectsApi();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { data: project, isFetching: isFetchingProject } = useQuery(
    ["project", projectId],
    () => {
      if (projectId) {
        return projectsApi.get(projectId);
      }

      return;
    }
  );

  const hasTeam = useMemo(() => {
    return !!project?.team;
  }, [project]);

  useEffect(() => {
    if (!requireApproval) {
      setRequireApproval(project?.requireApproval ?? false);
    }
  }, [project]);

  const title = useMemo(() => {
    return project?.name ?? "Untitled Project";
  }, [project]);

  return (
    <TitledPage
      title={title}
      isLoadingTitle={isFetchingProject}
      breadcrumbs={[
        {
          label: "Projects",
          link: "/projects",
        },
      ]}
      action={[
        {
          label: "View Team",
          icon: Eye,
          trigger: () => navigate(`/teams/${project?.team?.id}`),
        },
        {
          label: "Edit Project",
          icon: PencilSimple,
          trigger: () => setIsProjectModalOpen(true),
        },
      ]}
    >
      <ModifyProjectModal
        organisation={project?.organisation!}
        team={project?.team!}
        allowTeamSelection={false}
        project={project}
        isOpen={isProjectModalOpen}
        onClose={() => setIsProjectModalOpen(false)}
      />

      <Tabs marginTop={"12px"} isLazy>
        <TabList>
          <Tab>Entries</Tab>
          <Tab>Categories</Tab>
          <Tab>Billing</Tab>
          {hasTeam && <Tab>Approvals</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel paddingX={0}>
            <EntriesTab
              hasTeam={hasTeam}
              subtitle={"View entries for this project."}
            />
          </TabPanel>
          <TabPanel paddingX={0}>
            <CategoriesTab />
          </TabPanel>
          <TabPanel paddingX={0}>
            <BillingTab />
          </TabPanel>
          <TabPanel paddingX={0}>
            <ApprovalsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </TitledPage>
  );
};

export default ViewProjectPage;
