import {
  Button,
  useDisclosure,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { PencilSimple } from "phosphor-react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useTeamsApi from "../../../Api/Resources/Teams/TeamsApi";
import TitledPage from "../../../Components/TitledPage";
import { ModifyTeamModal } from "../../Account/Pages/Organisations/Components/ModifyTeamModal";
import { TeamMembersTab } from "./Components/TeamMembersTab";
import { TeamProjectsTab } from "./Components/TeamProjectsTab";

const ViewTeamPage = () => {
  const { teamId } = useParams();
  const teamsApi = useTeamsApi();
  const editTeamModal = useDisclosure();

  const { data: team, isFetching: isFetchingTeams } = useQuery(
    ["teams", teamId],
    () => {
      return teamsApi.get(teamId!);
    }
  );

  return (
    <TitledPage
      breadcrumbs={[{ label: "Teams", link: "/teams" }]}
      isLoading={isFetchingTeams}
      title={team?.name ?? ""}
      subtitle={team?.description ?? ""}
      action={{
        label: "Edit Team",
        icon: PencilSimple,
        trigger: editTeamModal.onOpen,
      }}
    >
      <Tabs marginTop={"12px"} isLazy>
        <TabList>
          <Tab>Team</Tab>
          <Tab>Projects</Tab>
        </TabList>
        <TabPanels>
          <TabPanel paddingX={0}>
            <TeamMembersTab team={team} />
          </TabPanel>
          <TabPanel paddingX={0}>
            <TeamProjectsTab team={team} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModifyTeamModal
        team={team}
        organisationId={team?.organisation?.id}
        isOpen={editTeamModal.isOpen}
        onClose={editTeamModal.onClose}
      />
    </TitledPage>
  );
};

export default ViewTeamPage;
