import axios from "axios";
import shallow from "zustand/shallow";
import { AuthTokens, useAuthStore } from "../Store/AuthStore";
import jwt from "jwt-decode";
import { isBefore, add } from "date-fns";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useMethods = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        const status = error.response.status;

        if (location.pathname === "/landing") {
          return;
        }

        // if (status === 401) {
        //   navigate("/unauthorised");
        // }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  });

  const { authTokens, setAuthTokens } = useAuthStore(
    (state) => ({
      setAuthTokens: state.setAuthTokens,
      authTokens: state.authTokens,
    }),
    shallow
  );

  const getConfig = async () => {
    if (authTokens) {
      let { accessToken, refreshToken } = authTokens;

      const decodedAccessToken = jwt(accessToken) as { exp: number };
      const { exp } = decodedAccessToken;
      const expirationDate = new Date(exp * 1000);

      const currentTime = new Date();
      const currentTimeLessFiveMinutes = add(currentTime, {
        minutes: 5,
      });

      if (isBefore(expirationDate, currentTimeLessFiveMinutes)) {
        // Need to refresh the token...
        try {
          const newAccessTokens = (
            await axios.get(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
              headers: {
                Authorization: "Bearer " + refreshToken,
              },
            })
          ).data as AuthTokens;

          // Update the access token...
          accessToken = newAccessTokens.accessToken;
          // Set the new access token, refresh token, and userId.
          setAuthTokens(newAccessTokens);
        } catch (e) {
          console.error("Could not refresh token.");
        }
      }

      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return {};
  };

  const post = async (url: string, data: any) => {
    const config = await getConfig();
    return axios.post(url, data, config);
  };

  const patch = async (url: string, data: any) => {
    const config = await getConfig();
    return axios.patch(url, data, config);
  };

  const deleteOne = async (url: string) => {
    const config = await getConfig();
    return axios.delete(url, config);
  };

  const get = async (url: string) => {
    const config = await getConfig();
    return axios.get(url, config);
  };

  const save = async (url: string) => {
    const config = await getConfig();

    return axios({
      url,
      method: "GET",
      responseType: "blob",
      ...config,
    }).then((response) => {
      const filename = response.headers["content-disposition"]?.split("=")[1]!;
      console.log("filename", filename);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return {
    get,
    post,
    deleteOne,
    patch,
    save,
  };
};

export default useMethods;
