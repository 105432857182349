import { Button, useDisclosure } from "@chakra-ui/react";
import { Placeholder, Plus, TrashSimple } from "phosphor-react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import useProjectMutation from "../../../../Api/Resources/Projects/ProjectsMutation";
import { ModifyProjectModal } from "../../../Projects/List/Components/ModifyProjectModal";
import CustomTable, { ColumnType } from "../../../../UIKit/Table/Table";
import { ButtonTableRow } from "../../../../UIKit/Table/ButtonTableRow";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Team } from "../../../../Api/Resources/Teams/TeamsApiTypes";
import useTeamsApi from "../../../../Api/Resources/Teams/TeamsApi";

export const TeamProjectsTab = (props: { team?: Team }) => {
  const { team } = props;
  const { teamId } = useParams();
  const addProjectModal = useDisclosure();
  const { deleteProject } = useProjectMutation();
  const teamsApi = useTeamsApi();

  const { data: projects, isFetching: isFetchingProjects } = useQuery(
    ["teamProjects", teamId],
    () => {
      return teamsApi.listProjects(teamId!);
    }
  );

  const onRemoveProject = (projectId: string) => {
    deleteProject.mutate({
      projectId,
      teamId,
    });
  };

  const rows = useMemo(() => {
    return (
      projects?.map((project) => {
        return {
          name: project.name,
          lastWorkedOn: "Some project",
        };
      }) ?? []
    );
  }, [projects]);

  return (
    <>
      <ModifyProjectModal
        organisation={team?.organisation}
        team={team}
        allowTeamSelection={false}
        isOpen={addProjectModal.isOpen}
        onClose={addProjectModal.onClose}
      />
      <Card isPadded={false}>
        <CardHeader
          title="Projects"
          subtitle="View, edit and remove team projects."
          action={
            <Button
              leftIcon={<Plus weight="bold" />}
              variant={"outline"}
              colorScheme={"blue"}
              onClick={addProjectModal.onOpen}
            >
              New Project
            </Button>
          }
        />

        <CustomTable
          placeholder={{
            label: "No projects",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            const { column } = options;

            switch (column.type) {
              case ColumnType.BUTTON: {
                return (
                  <ButtonTableRow
                    icon={TrashSimple}
                    onItemClicked={({ rowIndex, columnIndex }) => {
                      if (!projects) return;
                      const project = projects[rowIndex];

                      if (project.id) {
                        onRemoveProject(project.id);
                      }
                    }}
                    {...options}
                  />
                );
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={rows}
          columns={[
            { label: "Name", accessor: "name" },
            {
              label: "Remove",
              accessor: "remove",
              isNumeric: true,
              type: ColumnType.BUTTON,
            },
          ]}
        />
      </Card>
    </>
  );
};
