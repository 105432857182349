import useMethods from "../../useMethods";
import { Category } from "./CategoriesApiTypes";

const useCategoriesApi = () => {
  const methods = useMethods();

  const list = async (
    projectId: string,
    search: string = ""
  ): Promise<Category[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}/categories${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const create = async (category: Category): Promise<Category> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/projects/${category.projectId}/categories`,
      category
    );
    return result.data;
  };

  const update = async (category: Category): Promise<Category> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/projects/${category.projectId}/categories/${category.id}`,
      category
    );
    return result.data;
  };

  const deleteOne = async (
    projectId: string,
    categoryId: string
  ): Promise<Category> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}/categories/${categoryId}`
    );
    return result.data;
  };

  return {
    list,
    create,
    update,
    deleteOne,
  };
};

export default useCategoriesApi;
