import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Table as ChakraTable,
  Flex,
  VStack,
} from "@chakra-ui/react";
import Loading from "../Loading/Loading";

export enum ColumnType {
  TEXT,
  BUTTON,
  INPUT,
  AVATAR,
  USER
}

export type TableColumn = {
  label: string;
  accessor: string;
  isLink?: boolean;
  isNumeric?: boolean;
  type?: ColumnType;
  isDisabled?: boolean;
};

export type TableRow = {
  [key: string]: string | object | JSX.Element | JSX.Element[] | undefined;
};

export type RenderRowOptions = {
  row: TableRow;
  column: TableColumn;
  rowIndex: number;
  columnIndex: number;
  totalRows: number;
};

const TablePlaceholder = ({ icon, label }: { icon: any; label: string }) => {
  const Icon = icon;
  return (
    <VStack opacity={0.2} paddingTop={"16px"} paddingBottom={"16px"}>
      <Flex>
        <Icon size={72} />
      </Flex>
      <Flex fontSize={"24px"}>{label}</Flex>
    </VStack>
  );
};

const CustomTable = ({
  isLoading = false,
  rows,
  columns,
  renderRow,
  placeholder,
}: {
  isLoading?: boolean;
  rows: TableRow[];
  columns: TableColumn[];
  renderRow: (options: RenderRowOptions) => JSX.Element;
  placeholder: {
    label: string;
    icon: any;
  };
}) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <TableContainer width={"100%"}>
      {rows.length === 0 ? (
        <TablePlaceholder {...placeholder} />
      ) : (
        <ChakraTable size={"md"} variant="striped">
          <Thead>
            <Tr>
              {columns.map((column, columnIndex) => {
                return (
                  <Th isNumeric={column.isNumeric} key={columnIndex}>
                    {column.label}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, rowIndex) => {
              return (
                <Tr key={rowIndex}>
                  {columns.map((column, columnIndex) => {
                    return renderRow({
                      column,
                      columnIndex,
                      rowIndex,
                      row,
                      totalRows: rows.length,
                    });
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      )}
    </TableContainer>
  );
};

export default CustomTable;
