import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { CheckCircle, CircleWavy, Export, Placeholder } from "phosphor-react";
import { RangeDatePicker } from "../../../../UIKit/DatePicker/RangeDatePicker";
import CustomTable from "../../../../UIKit/Table/Table";
import { format, parseISO, startOfDay } from "date-fns";
import { formatTime } from "../../../../Utilities/TimeUtility";
import { useMemo, useState } from "react";
import useAnalysisApi from "../../../../Api/Resources/Analysis/AnalysisApi";
import { MultiValue } from "react-select";
import { useParams } from "react-router-dom";
import useCategoriesApi from "../../../../Api/Resources/Categories/CategoriesApi";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import useEntriesApi from "../../../../Api/Resources/Entries/EntriesApi";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";

export interface EntriesTabProps {
  subtitle?: string;
  selectedDates?: Date[];
  controls?: JSX.Element[] | JSX.Element;
}

const EntriesTab = (props: EntriesTabProps) => {
  const { personId: userId } = useParams();

  const [selectedDates, setSelectedDates] = useState<Date[]>();
  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<MultiValue<{ value: string; label: string }> | null>();
  const [selectedFilterProject, setSelectedFilterProject] =
    useState<MultiValue<{ value: string; label: string }> | null>();

  const analysisApi = useAnalysisApi();
  const categoriesApi = useCategoriesApi();
  const projectsApi = useProjectsApi();
  const entriesApi = useEntriesApi();

  const onExport = () => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (selectedDates?.length === 2) {
      startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
      endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
    }

    return analysisApi.exportData({
      startDate,
      endDate,
      members: [],
      categories:
        selectedFilterCategory?.map((category) => category.value) ?? [],
      projects: selectedFilterProject?.map((project) => project.value) ?? [],
    });
  };

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    [
      "entries",
      userId,
      selectedDates,
      selectedFilterCategory,
      selectedFilterProject,
    ],
    () => {
      if (userId) {
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (selectedDates?.length === 2) {
          startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
          endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
        }

        return entriesApi.listForUser({
          userId,
          startDate,
          endDate,
          categories:
            selectedFilterCategory?.map((category) => category.value) ?? [],
          projects:
            selectedFilterProject?.map((project) => project.value) ?? [],
        });
      }

      return;
    }
  );

  const loadCategories = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    categoriesApi.list("test", inputValue).then((categories) => {
      const result = categories.map((category) => {
        return {
          label: category.name ?? "Untitled Category",
          value: category.id!,
        };
      });

      callback(result);
    });
  };

  const loadProjects = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    projectsApi
      .listForUser({ userId: userId!, search: inputValue })
      .then((categories) => {
        const result = categories.map((category) => {
          return {
            label: category.name ?? "Untitled Project",
            value: category.id!,
          };
        });

        callback(result);
      });
  };

  const rows = useMemo(() => {
    return (entries ?? []).map((entry) => {
      return {
        description: entry.description,
        category: entry.category?.name,
        date: format(parseISO(entry.date), "yyyy-MM-dd"),
        time: formatTime(entry.time),
        users: (
          <AvatarGroup size="sm" max={2}>
            {entry?.users?.map((user) => {
              return <Avatar name={user.displayName} />;
            })}
          </AvatarGroup>
        ),
        approved: entry.approved ? <CheckCircle /> : <CircleWavy />,
      };
    });
  }, [entries]);

  return (
    <VStack spacing={4}>
      <Card isPadded={false}>
        <CardHeader
          title="Entries"
          subtitle={props.subtitle}
          action={
            <HStack>
              <Button
                leftIcon={<Export weight="bold" />}
                variant={"outline"}
                colorScheme={"blue"}
                onClick={onExport}
              >
                Export
              </Button>
            </HStack>
          }
        />
        <HStack spacing={4} justifyContent={"space-evenly"}>
          <FormControl>
            <FormLabel htmlFor="dateRange">Date Range</FormLabel>
            <RangeDatePicker
              name="dateRange"
              usePortal
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
          </FormControl>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Projects</FormLabel>
              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Projects"
                value={selectedFilterCategory}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterProject(null);
                  }

                  setSelectedFilterProject(value);
                }}
                loadOptions={loadProjects}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Categories</FormLabel>
              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Categories"
                value={selectedFilterCategory}
                isDisabled={selectedFilterProject === undefined}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterCategory(null);
                  }

                  setSelectedFilterCategory(value);
                }}
                loadOptions={loadCategories}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
        </HStack>
      </Card>

      <Card>
        {isFetchingEntries ? (
          <Spinner />
        ) : (
          <CustomTable
            placeholder={{
              label: "No entries",
              icon: Placeholder,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={rows}
            columns={[
              { label: "Description", accessor: "description" },
              { label: "Category", accessor: "category" },
              { label: "Date", accessor: "date" },
              { label: "Time", accessor: "time" },
              { label: "Users", accessor: "users" },
              { label: "Approved", accessor: "approved" },
            ]}
          />
        )}
      </Card>
    </VStack>
  );
};

export default EntriesTab;
