import useMethods from "../../useMethods";
import { Billing, Subscription, SubscriptionRecord } from "./BillingApiTypes";

const useBillingApi = () => {
  const methods = useMethods();

  const list = async (): Promise<Billing[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/billing/plans`
    );
    return result.data;
  };

  const createSubscription = async (
    priceId: string
  ): Promise<SubscriptionRecord> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/billing/create-subscription`,
      { priceId }
    );

    return result.data;
  };

  const cancelSubscription = async (
    subscriptionId: string
  ): Promise<SubscriptionRecord> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/billing/subscription/${subscriptionId}/cancel`
    );

    return result.data;
  };

  const getUserSubscription = async (): Promise<Subscription> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/billing/subscription`
    );

    return result.data;
  };

  return {
    list,
    createSubscription,
    getUserSubscription,
    cancelSubscription,
  };
};

export default useBillingApi;
